<template>
  <travio-center-container grid-width='2/3'>
    <h2 class="mb-4" style="color:#636363">Data Editor</h2>

    <vx-card>
      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material mb-3"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="googleSheets"
        :gridOptions="gridOptions"
        domLayout="autoHeight"
        rowSelection="single"
        :pagination="false"
        :suppressPaginationPanel="true"
      ></ag-grid-vue>
    </vx-card>
    
  </travio-center-container>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererGsheetLink from './cell-renderers/CellRendererGsheetLink.vue'
export default {
  components: {
    AgGridVue,
    CellRendererGsheetLink
  },
  props: {
    applicationId: { required: true }
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererGsheetLink
      },
      googleSheets: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  async created () {

    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }
    this.$vs.loading()

    try {
      const response = await this.$http.get(`api/googleSheets/apps/${this.applicationId}`)
      this.googleSheets = response.data

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { hide: true, field: 'appId' },
      { hide: true, field: 'sheetId' },
      { hide: true, field: 'googleSheetId' },
      { headerName: 'Editor Name', field: 'sheetName', suppressSizeToFit: false, sortable: true, width: 350 },
      { headerName: 'Link', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererGsheetLink) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
  },
  methods: {

  }
}
</script>

<style>

</style>
