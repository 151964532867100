var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "2/3" } },
    [
      _c("h2", { staticClass: "mb-4", staticStyle: { color: "#636363" } }, [
        _vm._v("Data Editor"),
      ]),
      _c(
        "vx-card",
        [
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material mb-3",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.googleSheets,
              gridOptions: _vm.gridOptions,
              domLayout: "autoHeight",
              rowSelection: "single",
              pagination: false,
              suppressPaginationPanel: true,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }