<template>
  <div>
    <feather-icon title="Launch" icon="ExternalLinkIcon" svgClasses="h-5 w-5 mr-3 hover:text-primary cursor-pointer" @click="onLaunch" />
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      gSheetsLink: false
    }
  },
  created () {
    this.gSheetsLink = `https://api.travelify.io/gsheeteditor?appid=${this.params.data.appId}&sheetid=${this.params.data.googleSheetId}`
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onLaunch () {
      window.open(this.gSheetsLink, "_blank");
    },
  }
}
</script>
